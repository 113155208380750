import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/seo";
//Images
import { StaticImage } from "gatsby-plugin-image";

const Architects = () => {
  return (
    <Layout>
      <Seo
        title="Arquitectos - BIKESAFE"
        description="Bike Safe ofrece una gama completa de aparcabicicletas que combinan seguridad, diseño estético y funcionalidad. Trabajamos estrechamente con arquitectos y planificadores para cumplir con los más altos estándares de calidad."
      />
      <Container>
        <Row>
          <Col sm="12">
            <h1 className="mt-5 mb-5 text-center">Arquitectos</h1>
          </Col>
        </Row>
        <Row>
          <Col lg="6" sm="12">
            <h5 className="float-lg-left subheading-product uppercase">
              Seguridad y Estilo en el Diseño:
            </h5>
          </Col>
          <Col lg="6" sm="12">
            <p className="flow-text">
              En Bike Safe, ofrecemos una amplia gama de aparcabicicletas que
              combinan máxima seguridad con diseños estéticos y escultóricos.
              Entendemos la importancia del diseño en los proyectos
              arquitectónicos, y por ello, nuestros productos cumplen con los
              más altos niveles de calidad y funcionalidad. Creamos soluciones
              que no solo fomentan el uso seguro de las bicicletas, sino que
              también complementan el entorno urbano.
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="mt-5 mb-5 text-center">
            <StaticImage
              placeholder="blurred"
              src="../images/projects.png"
              className="shadow fluid rounded"
            />
          </Col>
        </Row>
        <Row>
          <Col lg="6" sm="12">
            <h5 className="float-lg-left subheading-product uppercase">
              Servicio de Diseño CAD Personalizado:
            </h5>
          </Col>
          <Col lg="6" sm="12">
            <p className="flow-text">
              Ofrecemos un servicio integral de diseño, incluyendo visitas al
              emplazamiento y un completo soporte CAD. Nuestro diseñador,
              Stephen, cuenta con una amplia experiencia trabajando con
              arquitectos para desarrollar diseños innovadores y exclusivos.
              Estamos comprometidos a proporcionar soluciones que se adapten a
              la visión de su proyecto. Contáctenos para obtener más información
              sobre nuestros servicios y precios.
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Architects;
